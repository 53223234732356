import axios from '../utils/axiosInstance';
import history from '../utils/history';
import setAuthToken from '../utils/setAuthToken';
import { OTP_VERIFICATION_API, GET_USER_INFO } from '../utils/constants';
import { logDev } from '../utils/logger';

const LOGIN_API = '/users/signin';

// Action types
const START_LOGIN_USER = 'START_LOGIN_USER';
const START_OTP_VERIFICATION = 'START_OTP_VERIFICATION';
const LOGOUT_USER = 'LOGOUT_USER';

// Action creators
const loginUser = (userData) => ({
  type: START_LOGIN_USER,
  payload: userData,
});

const logoutUser = () => ({
  type: LOGOUT_USER,
});

// Thunk actions
export const startLoginUser = (payload, history) => async (dispatch) => {
  let error = null;
  let loading = true;
  let data = null;
  
  try {
    const response = await axios.post(OTP_VERIFICATION_API, payload);
    data = response.data;
    const token = data.data.token;

    sessionStorage.setItem('token', token);

    const userDetailsResponse = await axios.get(GET_USER_INFO, {
      headers: {
        Authorization: `Token ${token}`, 
      },
    });

    if (token) {
      const { data: userdetails } = userDetailsResponse;

      sessionStorage.setItem('isAuthenticated', true);
      const authDetails = {
        isAuthenticated: true,
        user: {
          ...userdetails.data.agent
        },
        bank: {
          ...data.data.bank_info
        },
        has_pin: userdetails.data.has_pin,
        image: userdetails.data.image,
      };
      setAuthToken(token);
      dispatch(loginUser(authDetails));
      sessionStorage.setItem('user', JSON.stringify(authDetails));
      history.replace('/overview');
      window.location.reload();
    }
  } catch (err) {
    if (err.response) {
      const { status, message, errors } = err.response.data;
      error = {
        status,
        message,
        errors
      };
      if (status === "Error") {
        if (message && errors.length === 0) {
          logDev(message, 'error');
        } else if (message && errors.length > 0) {
          logDev(errors[0], 'error');
        }
      }
    } else {
      error = {
        message: err.message,
      };
    }
  } finally {
    loading = false;
    return { error, loading, data };
  }
};

export const startLogout = () => (dispatch) => {
  dispatch({
    type: 'SET_LOADING',
    payload: {
      loading: false,
      message: undefined,
    },
  });

  sessionStorage.clear('user');
  sessionStorage.clear('token');
  sessionStorage.clear('balance');
  history.push('/');
  dispatch(logoutUser());
};
